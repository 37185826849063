import React, { createContext, useState, ReactNode, useEffect } from 'react';
import useDecodeUrl from '../hooks/useDecodeUrl';
import {
  Activity,
  BankDetailsInformations,
  City,
  DocumentsInformations,
  GuaranteeInformations,
  SubChildrenInformations,
  SubPersonalInformations,
  SubSpouseInformations,
  QuoteData,
  QuoteApiParams
} from '../types/Situation';
import { useCityFetcher } from 'src/services/cityUtils';
import { useQuotePriceYearFetcher } from 'src/services/quotePriceYearUtils';
import { useActivityFetcher } from 'src/services/activityUtils';
import { formatDate } from 'src/services/dateUtils';
import { useLoaderData } from "react-router-dom";
import { useQuoteDataFetcher } from 'src/services/quoteDataUtils';
import { sanitizeData } from 'src/services/genericUtils';

interface SubscriptionContextProps {
  step: number;
  setStep: React.Dispatch<React.SetStateAction<number>>;
  offer: number;
  guaranteeInformations: GuaranteeInformations;
  setGuaranteeInformations: React.Dispatch<React.SetStateAction<GuaranteeInformations>>;
  personalInformations: SubPersonalInformations;
  setPersonalInformations: React.Dispatch<React.SetStateAction<SubPersonalInformations>>;
  spouseInformations: SubSpouseInformations;
  setSpouseInformations: React.Dispatch<React.SetStateAction<SubSpouseInformations>>;
  childrenInformations: SubChildrenInformations[];
  setChildrenInformations: React.Dispatch<React.SetStateAction<SubChildrenInformations[]>>;
  bankDetailsInformations: BankDetailsInformations;
  setBankDetailsInformations: React.Dispatch<React.SetStateAction<BankDetailsInformations>>;
  documentsInformations: DocumentsInformations;
  setDocumentsInformations: React.Dispatch<React.SetStateAction<DocumentsInformations>>;
  signature: { subscription: string; mandate: string };
  setSignature: React.Dispatch<React.SetStateAction<{ subscription: string; mandate: string }>>;
  activity: Activity;
  spouseActivity: Activity;
  quotePriceYear: number;
  cities: Array<City>;
  quoteData: QuoteData;
  setQuoteData: React.Dispatch<React.SetStateAction<QuoteData>>;
  setQuoteDataPersonnalInformation: (data: Partial<SubPersonalInformations>) => void;
  setQuoteDataSpouseInformation: (data: Partial<SubSpouseInformations>) => void;
  setQuoteDataChildInformation: (data: Partial<SubChildrenInformations>, index: number) => void;
}

const SubscriptionContext = createContext<SubscriptionContextProps | undefined>(undefined);

const SubscriptionProvider = ({ children }: { children: ReactNode }) => {
  const data = useDecodeUrl();
  const [step, setStep] = useState(0);

  // const offer = data.offer;
  const offer = 1;

  const [guaranteeInformations, setGuaranteeInformations] = useState<GuaranteeInformations>({
    hasBeenSponsored: false,
    sponsoredName: '',
    sponsoredReference: '',
    hasComplementaryHealthInsurance: false,
    needWipeOut: false,
    currentHealthInsuranceReference: '',
    currentHealthInsuranceName: '',
    adressWipeOut: '',
    postalCodeWipeOut: '',
    cityWipeOut: '',
    countryWipeOut: '',
    startingDate: data.situationState.accessionDate,
  });

  const [personalInformations, setPersonalInformations] = useState<SubPersonalInformations>({
    civility: data.personalInformations.civility,
    firstname: data.personalInformations.firstname,
    lastname: data.personalInformations.lastname,
    birthday: data.situationState.birthday,
    streetName: data.personalInformations.streetName,
    streetNumber: data.personalInformations.streetNumber,
    streetMore: data.personalInformations.streetMore,
    postalCode: data.situationState.postalCode,
    city: data.personalInformations.city,
    country: data.personalInformations.country,
    email: data.personalInformations.email,
    phonePrefix: data.personalInformations.phonePrefix,
    phone: data.personalInformations.phone,
    phoneFixPrefix: '',
    phoneFix: '',
    insee: '',
    activity: data.situationState.activity,
    athletic: data.situationState.athletic,
    currentState: data.situationState.currentState,
    situation: data.situationState.situation,
    socialSecurityScheme: data.situationState.socialSecurityScheme,
    check: false
  });

  const [spouseInformations, setSpouseInformations] = useState<SubSpouseInformations>({
    civility: '',
    firstname: '',
    lastname: '',
    birthday: data.situationState.spouse.birthday,
    activity: data.situationState.spouse.activity,
    socialSecurityConnection: 'no',
    insee: '',
    athletic: data.situationState.spouse.athletic,
    socialSecurityScheme: data.situationState.spouse.socialSecurityScheme
  });

  const [childrenInformations, setChildrenInformations] = useState<SubChildrenInformations[]>(
    data.situationState.children.map(({ birthday }: SubChildrenInformations) => ({
      civility: '',
      firstname: '',
      lastname: '',
      birthday: birthday,
      socialSecurityConnection: 'no',
      insee: '',
      socialSecurityScheme: ''
    }))
  );

  const [bankDetailsInformations, setBankDetailsInformations] = useState<BankDetailsInformations>({
    ownerRib: '',
    rib: '',
    ibanFee: '',
    bicFee: '',
    dateFee: 'mensuel',
    isOwner: true,
    additionalAccountFirstName: '',
    additionalAccountLastName: '',
    additionalAccountAdress: '',
    additionalAccountPostalCode: '',
    additionalAccountCity: '',
    sameHolderFee: true,
    refundAccoundOwner: '',
    refundAccoundRib: '',
    refundAccountIban: '',
    refundAccountBic: '',
    refundSpouseSameAccount: true,
    refundSpouseAccoundOwner: '',
    refundSpouseAccoundRib: '',
    refundSpouseAccountIban: '',
    refundSpouseAccountBic: '',
  });

  const [documentsInformations, setDocumentsInformations] = useState<DocumentsInformations>({
    identityDocument: null,
    healthInsuranceCertificate: null,
    bankIdentityDocument: null,
    sportCertificate: null,
    identifySpouseDocument: null,
  });

  const [signature, setSignature] = useState({
    subscription: '',
    mandate: '',
  });

  const activity = useActivityFetcher(personalInformations.situation, personalInformations.socialSecurityScheme, personalInformations.activity);
  const spouseActivity = useActivityFetcher(personalInformations.situation, personalInformations.socialSecurityScheme, spouseInformations.activity, 'c');
  const quotePriceYear = useQuotePriceYearFetcher();
  const cities = useCityFetcher(personalInformations.postalCode);

  const loaderData = useLoaderData() as QuoteApiParams;
  const registeredData = useQuoteDataFetcher(loaderData.quoteNumber);
  const [quoteData, setQuoteData] = useState({} as QuoteData);

  const setQuoteDataPersonnalInformation = (data: Partial<SubPersonalInformations>) => {
    const sanitizedData = sanitizeData(data);
    setQuoteData((previousState) => ({ ...previousState, member: {
      ...previousState.member,
      ...sanitizedData,
      birthday: formatDate(previousState.member.birthday),
    } }));
  }

  const setQuoteDataSpouseInformation = (data: Partial<SubSpouseInformations>) => {
    const sanitizedData = sanitizeData(data);
    setQuoteData((previousState) => ({ ...previousState, spouse: {
      ...previousState.spouse,
      ...sanitizedData,
      birthday: formatDate(previousState.spouse.birthday),
    } }));
  }

  const setQuoteDataChildInformation = (data: Partial<SubChildrenInformations>, index: number) => {
    const sanitizedData = sanitizeData(data);
    setQuoteData((previousState) => {
      const children = [...previousState.children];
      children[index] = {
        ...children[index],
        ...sanitizedData,
        birthday: formatDate(previousState.children[index].birthday),
      }

      return { ...previousState, children };
    });
  }

  useEffect(() => {
    if (0 === Object.keys(registeredData).length) return;
    setQuoteData(registeredData.quote);
    setQuoteDataPersonnalInformation(registeredData.quote.member);
    setQuoteDataSpouseInformation(registeredData.quote.spouse);
    for (let i = 0; i < registeredData.quote.children.length; i++) {
      setQuoteDataChildInformation(registeredData.quote.children[i], i);
    }
  }, [setQuoteData, registeredData]);

  useEffect(() => {
    if (0 === Object.keys(quoteData).length) return;

    setPersonalInformations((previousState) => ({
      ...previousState,
      civility: quoteData.member.civility ?? '',
      firstname: quoteData.member.firstname ?? '',
      lastname: quoteData.member.lastname ?? '',
      birthday: formatDate(quoteData.member.birthday),
      streetName: quoteData.member.streetName ?? '',
      streetNumber: quoteData.member.streetNumber ?? '',
      streetMore: quoteData.member.streetMore ?? '',
      postalCode: quoteData.member.postalCode ?? '',
      country: quoteData.member.country ?? '',
      email: quoteData.member.email ?? '',
      phone: quoteData.member.phone ?? '',
      situation: quoteData.member.situation ?? '',
      activity: quoteData.member.activity ?? '',
      socialSecurityScheme: quoteData.member.socialSecurityScheme ?? '',
      currentState: quoteData.member.currentState ?? '',
      insee: quoteData.member.insee ?? '',
      phonePrefix: quoteData.member.phonePrefix ?? '',
      phoneFix: quoteData.member.phoneFix ?? '',
      athletic: quoteData.member.athletic ?? '',
    }));

    setSpouseInformations((previousState) => ({
      ...previousState,
      birthday: formatDate(quoteData.spouse.birthday),
      activity: quoteData.spouse.activity ?? '',
      socialSecurityConnection: 'RA' === quoteData.spouse.socialSecurityScheme ? 'yes' : 'other',
      insee: quoteData.spouse.insee ?? '',
      socialSecurityScheme: quoteData.spouse.socialSecurityScheme ?? '',
    }));

    setGuaranteeInformations((previousState) => ({
      ...previousState,
      startingDate: formatDate(quoteData.subscriptionStartPlanned),
    }));

    setChildrenInformations(() => {
      const children: SubChildrenInformations[] = [];
      for (let i = 0; i < quoteData.totalChild; ++i) {
        children.push({
          civility: '',
          firstname: '',
          lastname: '',
          birthday: '',
          socialSecurityConnection: 'no',
          insee: '',
          socialSecurityScheme: '',
        });
      }

      return children;
    });
  }, [quoteData]);

  return (
    <SubscriptionContext.Provider value={{
      step,
      setStep,
      offer,
      guaranteeInformations,
      setGuaranteeInformations,
      personalInformations,
      setPersonalInformations,
      spouseInformations,
      setSpouseInformations,
      childrenInformations,
      setChildrenInformations,
      bankDetailsInformations,
      setBankDetailsInformations,
      documentsInformations,
      setDocumentsInformations,
      signature,
      setSignature,
      activity,
      quotePriceYear,
      cities,
      spouseActivity,
      quoteData,
      setQuoteData,
      setQuoteDataPersonnalInformation,
      setQuoteDataSpouseInformation,
      setQuoteDataChildInformation
    }}>
      {children}
    </SubscriptionContext.Provider>
  );
};

export { SubscriptionProvider, SubscriptionContext };
