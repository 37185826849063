import { useEffect, useState } from "react";

import { RegisteredQuote } from "src/types/Situation";
import { getRegisteredQuote } from "src/services/api";

export const useQuoteDataFetcher = (quoteNumber: string): RegisteredQuote => {
    const [quoteData, setQuoteData] = useState({} as RegisteredQuote);
    useEffect(() => {
        getRegisteredQuote(quoteNumber).then(data => {
            if (!data) return;
            setQuoteData(data);
        })
    }, [setQuoteData, quoteNumber]);
    
    return quoteData;
};