export const isDateValid = (date: string) => {
  const dateArray = date.split("/");
  if (dateArray.length !== 3) {
    return false;
  }
  const day = parseInt(dateArray[0]);
  const month = parseInt(dateArray[1]);
  const year = parseInt(dateArray[2]);
  const dateObject = new Date(year, month - 1, day);
  return (
    dateObject.getFullYear() === year &&
    dateObject.getMonth() === month - 1 &&
    dateObject.getDate() === day
  );
};

export const isAccessionDateValid = (date: string) => {
  const dateArray = date.split("/");
  if (dateArray.length !== 3) {
    return false;
  }
  const day = parseInt(dateArray[0]);
  const month = parseInt(dateArray[1]);
  const year = parseInt(dateArray[2]);
  const now = new Date();

  return (
    year > now.getFullYear() ||
    (year === now.getFullYear() && month > now.getMonth()) ||
    (year === now.getFullYear() &&
      month === now.getMonth() &&
      day >= now.getDate())
  );
};

export const adhHasMajority = (date: string) => {
  const dateArray = date.split('/');
  if (3 !== dateArray.length) return false;

  const day = parseInt(dateArray[0]);
  const month = parseInt(dateArray[1]);
  const year = parseInt(dateArray[2]);
  const now = new Date();
  const birthday = new Date(year, month - 1, day);
  const age = new Date(now.getTime() - birthday.getTime());

  return age.getUTCFullYear() - 1970 >= 18;
};

export const formatDate = (date: string, separator: string = '/', finalFormat: string = '$1-$2-$3'): string => {
  const newDate = new Date(date.replace(/(\d{4})[-/\s]*(\d{2})[-/\s]*(\d{2})/, finalFormat));

  return newDate.getDate() + separator + (newDate.getMonth() + 1) + separator + newDate.getFullYear();
};

export const isBirthdayValid = (birthday: string): boolean => {
  return isDateValid(birthday) && 10 === birthday.length && adhHasMajority(birthday);
};

export const getMaxDateBirthday = (): string => {
  const now = new Date();

  return now.getFullYear() - 18 + '-' + (now.getMonth() + 1) + '-' + now.getDate();
};