import { useState } from 'react';

import OffersModal from 'src/components/Ui/Modals/OffersModal';
import useSubscription from 'src/hooks/useSubscription';
import { devisData } from "src/data/DevisData";
import DevisResume from "src/components/Devis/Resume";
import NextButton from "src/components/Ui/NextButton";
import MyInformations from "./MyInformations";
import SpouseInformation from "./SpouseInformations";
import ChildrenInformation from "./ChildrenInformations";
import { formInvalid } from "src/services/validatorsSubscription";
import { setSubscriptionStep } from "src/services/api-subscription-steps";

import styles from './styles.module.css';
import MySituationOfferModal from 'src/components/Ui/Modals/MySituationOfferModal';

const PersonalInformation = () => {
  const [modal, setModal] = useState(false);
  const [jobModal, setJobModal] = useState(false);
  const context = useSubscription();

  const { setStep, offer, personalInformations, quoteData } = context;

  const devis = devisData.find((devis) => devis.id === offer);
  return (
    <div className={`flex justify-center p-4 gap-12 rounded-md ${styles.responsivContainer}`}>
      <div className="flex flex-col items-start space-y-4 relative overflow-hidden gap-12 m-auto md:m-0">
        <MyInformations
          handleModal={() => setJobModal(true)}
        />
        {(personalInformations.currentState === 'allMyFamily' ||
          personalInformations.currentState === 'meAndMySpouse') &&
          <SpouseInformation />
        }
        {(personalInformations.currentState === 'allMyFamily' ||
          personalInformations.currentState === 'meAndMyChildren') &&
          <ChildrenInformation />
        }
      </div>
      {devis &&
        <div className="flex flex-col">
          <DevisResume devis={devis} withoutSubscribeButton actionConditions={() => setModal(true)} />
        </div>
      }

      <NextButton
        canPrev={true}
        canNext={!formInvalid({ quoteData })}
        nextStep={() => {
          setStep(2);
          setSubscriptionStep(2, quoteData.quoteNumber);
        }}
        prevStep={() => setStep(0)}
      />
      {
        modal && (
          <OffersModal
            close={() => setModal(false)}
            isAthletic={'true'}
          />
        )
      }
      {
        jobModal && (
          <MySituationOfferModal
            close={() => setJobModal(false)}
          />
        )
      }
    </div>
  );
}

export default PersonalInformation;