import React from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { format, parse, isValid } from 'date-fns'
import styles from './styles.module.css'
import Calendar from 'src/assets/Calendar';

interface InputProps {
  onChange: (value: string | number) => void
  value: string | number
  type?: 'text' | 'number' | 'email' | 'date'
  id?: string
  maxLength?: number
  label?: string
  minDate?: string
  maxDate?: string
  disabled?: boolean
  error?: boolean
}

const Input = ({
  onChange,
  value,
  type = 'text',
  id = '',
  maxLength,
  label,
  minDate = '',
  maxDate = '',
  disabled = false,
  error = false
}: InputProps) => {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [hasError, setHasError] = React.useState(false)

  // Handle changes for the date input
  const handleDateChange = (date: Date | null) => {
    if (date) {
      const formattedDate = format(date, 'dd/MM/yyyy')
      onChange(formattedDate)
    }
  }

  return type === 'date' ?
    (
      <>
        {label && (
          <label htmlFor={id} className={`flex items-center ${styles.label}`}>
            {label}
          </label>
        )}
        <div
          className={`${styles.input}`}
        >
          <Calendar />
          <DatePicker
            selected={isValid(parse(value as string, 'dd/MM/yyyy', new Date())) ? parse(value as string, 'dd/MM/yyyy', new Date()) : null}
            onChange={handleDateChange}
            dateFormat="dd/MM/yyyy"
            minDate={minDate ? new Date(minDate) : undefined}
            maxDate={maxDate ? new Date(maxDate) : undefined}
            placeholderText="jj/mm/aaaa"
            className={`${styles.date}`}
            onBlur={(e) => setHasError(error)}
            disabled={disabled}
            showPopperArrow={false}  // Option to hide the arrow from the popover if you want to customize further
          />
        </div>
      </>
    ) :
    (
      <>
        {label && (
          <label htmlFor={id} className={`flex items-center ${styles.label}`}>
            {label}
          </label>
        )}
        <input
          ref={inputRef}
          className={styles.input}
          onChange={(e) => onChange(e.target.value)}
          value={value}
          type={type}
          id={id}
          maxLength={maxLength}
          min={minDate}
          max={maxDate}
          disabled={disabled}
          onBlur={(e) => setHasError(error)}
          style={{ borderColor: hasError ? '#FF2E4D' : '#D1D5DB' }}
        />
        {hasError && <span className={styles.error}>Veuillez compléter ce champ</span>}
      </>
    )
  }

export default Input;