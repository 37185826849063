import { Devis } from "src/types/Situation";

import Me from "src/assets/Situations/Me";
import MeAndMySpouse from "src/assets/Situations/MeAndMySpouse";
import MeAndMyChildren from "src/assets/Situations/MeAndMyChildren";
import AllMyFamily from "src/assets/Situations/AllMyFamily";
import Dental from 'src/assets/Needed/Dental';
import Optic from 'src/assets/Needed/Optic';
import Hospitalization from 'src/assets/Needed/Hospitalization';
import CurrentCare from 'src/assets/Needed/CurrentCare';
import Hearing from 'src/assets/Needed/Hearing';
import Ring from 'src/assets/Needed/Ring';
import Gift from 'src/assets/Needed/Gift';
import Download from 'src/assets/Needed/Download';

const socialSecurityRegimeValues = [
  { label: 'Sécurité sociale', value: 'socialSecurity' },
  { label: 'Sécurité sociale agricole', value: 'agriculturalSocialSecurity' },
  { label: 'Sécurité sociale (CFE inclus)', value: 'socialSecurityCfe' },
  { label: 'Luxembourg', value: 'luxembourg' },
  { label: 'Sécurité sociale agricole est', value: 'agriculturalSocialSecurityEast' },
  { label: 'Sécurité sociale de l\'est', value: 'eastSocialSecurity' },
  { label: 'Europe', value: 'europe' },
  { label: 'Travailleur non salarié', value: 'selfEmployed' }
];

const activityTypeValues = [
  { label: 'Agriculteur', value: 'farmer' },
  { label: 'Apprentissage', value: 'apprenticeship' },
  { label: 'Contrat de qualification', value: 'qualificationContract' },
  { label: 'Demandeur d\'emploi', value: 'jobSeeker' },
  { label: 'Étudiant', value: 'student' },
  { label: 'Retraité', value: 'retired' },
  { label: 'Salarié', value: 'employee' }
];

const spouseActivityTypeValues = [
  { label: 'Agriculteur', value: 'farmer' },
  { label: 'Apprentissage', value: 'apprenticeship' },
  { label: 'Artisan', value: 'craftsman' },
  { label: 'Auto Entrepreneur', value: 'selfEmployed' },
  { label: 'Commerçant', value: 'trader' },
  { label: 'Contrat Qualification', value: 'qualificationContract' },
  { label: 'Demandeur D\'emploi', value: 'jobSeeker' },
  { label: 'Étudiant', value: 'student' },
  { label: 'Profession Libérale', value: 'liberalProfession' },
  { label: 'Profession Santé Salarié', value: 'healthProfessionalEmployee' },
  { label: 'Professionnel De Santé Libéral', value: 'healthProfessionalLiberal' },
  { label: 'Retraité', value: 'retired' },
  { label: 'Salarié', value: 'employee' },
  { label: 'Sans Activité', value: 'noActivity' }
];

const stateValues = [
  {
    label: 'Agent Col. Territoriales (garanties labellisées)', value: 'territorialCivilServant',
    socialSecurityRegimeValues: [
      { label: 'Sécurité sociale', value: 'socialSecurity' },
    ],
    outside: false,
    noOutside: true,
    id: 9200,
  },
  {
    label: 'Expatrié ou détaché - Maghreb', value: 'expatriateOrSeconded',
    socialSecurityRegimeValues: [
      { label: 'Sécurité sociale (CFE inclus)', value: 'socialSecurityCfe' },
    ],
    outside: true,
    noOutside: true,
    id: 9400,
  },
  {
    label: 'Expatrié ou détaché - Monde', value: 'expatriateOrSecondedWorld',
    socialSecurityRegimeValues: [
      { label: 'Luxembourg', value: 'luxembourg' },
      { label: 'Sécurité sociale (CFE inclus)', value: 'socialSecurityCfe' },
    ],
    outside: true,
    noOutside: false,
    id: 9040,
  },
  {
    label: 'Particulier - Agriculteur, Etudiant, salarié, retraité, demandeur d\'emploi ...', value: 'individual',
    socialSecurityRegimeValues: [
      { label: 'Luxembourg', value: 'luxembourg' },
      { label: 'Sécurité sociale', value: 'socialSecurity' },
      { label: 'Sécurité sociale agricole', value: 'agriculturalSocialSecurity' },
    ],
    outside: false,
    noOutside: true,
    id: 9000,
  },
  {
    label: 'Particulier - Régime Alsace Moselle', value: 'individualAlsaceMoselle',
    socialSecurityRegimeValues: [
      { label: 'Sécurité sociale agricole est', value: 'agriculturalSocialSecurityEast' },
      { label: 'Sécurité sociale de l\'est', value: 'eastSocialSecurity' },
    ],
    outside: false,
    noOutside: true,
    id: 9100,
  },
  {
    label: 'Particulier - Régime Institutions Union Européenne', value: 'individualEuropeanUnionInstitutions',
    socialSecurityRegimeValues: [
      { label: 'Europe', value: 'europe' },
    ],
    outside: true,
    noOutside: true,
    id: 9104,
  },
  {
    label: 'Professionnel santé - Libéral', value: 'healthProfessionalLiberal',
    socialSecurityRegimeValues: [
      { label: 'Sécurité sociale', value: 'socialSecurity' },
      { label: 'Travailleur non salarié', value: 'selfEmployed' },
    ],
    outside: false,
    noOutside: true,
    id: 9301,
  },
  {
    label: 'Professionnel Santé - salarié', value: 'healthProfessionalEmployee',
    socialSecurityRegimeValues: [
      { label: 'Sécurité sociale', value: 'socialSecurity' },
    ],
    outside: false,
    noOutside: true,
    id: 9100,
  },
  {
    label: 'Travailleur non salarié', value: 'selfEmployed',
    socialSecurityRegimeValues: [
      { label: 'Travailleur non salarié', value: 'selfEmployed' },
    ],
    outside: false,
    noOutside: true,
    id: 9300,
  }
];

const situationValues = [
  { label: 'Moi', value: 'me', icon: <Me /> },
  { label: 'Mon conjoint et moi', value: 'meAndMySpouse', icon: <MeAndMySpouse /> },
  { label: 'Mes enfants et moi', value: 'meAndMyChildren', icon: <MeAndMyChildren /> },
  { label: 'Toute la famille', value: 'allMyFamily', icon: <AllMyFamily /> }
];

const devisData: Devis[] = [
  {
    code: '123456',
    id: 1,
    name: 'Primo',
    price: 52,
    dentalScore: 4,
    dentalDropdown: [
      { label: '10€ remboursés', reduction: 'sur 40€' },
      { label: '', reduction: '' },
      { label: '20€ remboursés', reduction: 'sur 60€' },
      { label: '25€ remboursés', reduction: 'sur 75€' },
      { label: '30€ remboursés', reduction: 'sur 90€' },
    ],
    opticalScore: 4,
    opticalDropdown: [
      { label: '15€ remboursés', reduction: 'sur 50€' },
      { label: '20€ remboursés', reduction: 'sur 70€' },
    ],
    hospitalizationScore: 4,
    hospitalizationDropdown: [
      { label: '50€ remboursés', reduction: 'sur 200€' },
      { label: '60€ remboursés', reduction: 'sur 220€' },
      { label: '', reduction: '' },
      { label: '70€ remboursés', reduction: 'sur 250€' },
      { label: '80€ remboursés', reduction: 'sur 300€' },
    ],
    standardCareScore: 4,
    standardCareDropdown: [
      { label: '', reduction: '' },
      { label: '15€ remboursés', reduction: 'sur 50€' },
      { label: '20€ remboursés', reduction: 'sur 60€' },
      { label: '', reduction: '' },
      { label: '25€ remboursés', reduction: 'sur 75€' },
      { label: '30€ remboursés', reduction: 'sur 90€' },
      { label: '', reduction: '' },
      { label: '35€ remboursés', reduction: 'sur 100€' },
      { label: '40€ remboursés', reduction: 'sur 120€' },
    ],
    hearingScore: 4,
    hearingDropdown: [
      { label: '15€ remboursés', reduction: 'sur 50€' },
      { label: '20€ remboursés', reduction: 'sur 70€' },
    ],
    includedServices: [
      'Assistance',
      'Réseau de soins',
      'Informations de santé et de prévention',
      'Offres privilèges',
      'Téléconsultation'
    ],
    includedBenetifs: [
      '10% de remise pour les sportifs',
      '1 mois offert sur votre cotisation santé',
    ],
    documents: [
      {
        title: 'Grille des garanties',
        url: 'https://www.google.com',
      },
      {
        title: 'Exemples de remboursement',
        url: 'https://www.google.com',
      },
      {
        title: 'Services inclus',
        url: 'https://www.google.com',
      }
    ],
    promoted: false,
  },
  {
    code: '123457',
    id: 2,
    name: 'Primo Option',
    price: 62,
    dentalScore: 4,
    dentalDropdown: [
      { label: '12€ remboursés', reduction: 'sur 45€' },
      { label: '', reduction: '' },
      { label: '22€ remboursés', reduction: 'sur 65€' },
      { label: '27€ remboursés', reduction: 'sur 80€' },
      { label: '32€ remboursés', reduction: 'sur 95€' },
    ],
    opticalScore: 4,
    opticalDropdown: [
      { label: '18€ remboursés', reduction: 'sur 55€' },
      { label: '23€ remboursés', reduction: 'sur 75€' },
    ],
    hospitalizationScore: 4,
    hospitalizationDropdown: [
      { label: '55€ remboursés', reduction: 'sur 210€' },
      { label: '65€ remboursés', reduction: 'sur 230€' },
      { label: '', reduction: '' },
      { label: '75€ remboursés', reduction: 'sur 260€' },
      { label: '85€ remboursés', reduction: 'sur 310€' },
    ],
    standardCareScore: 4,
    standardCareDropdown: [
      { label: '', reduction: '' },
      { label: '17€ remboursés', reduction: 'sur 55€' },
      { label: '22€ remboursés', reduction: 'sur 65€' },
      { label: '', reduction: '' },
      { label: '27€ remboursés', reduction: 'sur 80€' },
      { label: '32€ remboursés', reduction: 'sur 95€' },
      { label: '', reduction: '' },
      { label: '37€ remboursés', reduction: 'sur 105€' },
      { label: '42€ remboursés', reduction: 'sur 125€' },
    ],
    hearingScore: 4,
    hearingDropdown: [
      { label: '17€ remboursés', reduction: 'sur 55€' },
      { label: '22€ remboursés', reduction: 'sur 75€' },
    ],
    includedServices: [
      'Assistance',
      'Réseau de soins',
      'Informations de santé et de prévention',
      'Offres privilèges',
      'Téléconsultation',
    ],
    includedBenetifs: [
      '10% de remise pour les sportifs',
      '1 mois offert sur votre cotisation santé',
    ],
    documents: [
      {
        title: 'Grille des garanties',
        url: 'https://www.google.com',
      },
      {
        title: 'Exemples de remboursement',
        url: 'https://www.google.com',
      },
      {
        title: 'Services inclus',
        url: 'https://www.google.com',
      }
    ],
    promoted: true,
  },
  {
    code: '123458',
    id: 3,
    name: 'Réflex',
    price: 70,
    dentalScore: 4,
    dentalDropdown: [
      { label: '14€ remboursés', reduction: 'sur 50€' },
      { label: '', reduction: '' },
      { label: '24€ remboursés', reduction: 'sur 70€' },
      { label: '29€ remboursés', reduction: 'sur 85€' },
      { label: '34€ remboursés', reduction: 'sur 100€' },
    ],
    opticalScore: 4,
    opticalDropdown: [
      { label: '20€ remboursés', reduction: 'sur 60€' },
      { label: '25€ remboursés', reduction: 'sur 80€' },
    ],
    hospitalizationScore: 4,
    hospitalizationDropdown: [
      { label: '60€ remboursés', reduction: 'sur 220€' },
      { label: '70€ remboursés', reduction: 'sur 240€' },
      { label: '', reduction: '' },
      { label: '80€ remboursés', reduction: 'sur 270€' },
      { label: '90€ remboursés', reduction: 'sur 320€' },
    ],
    standardCareScore: 4,
    standardCareDropdown: [
      { label: '', reduction: '' },
      { label: '19€ remboursés', reduction: 'sur 60€' },
      { label: '24€ remboursés', reduction: 'sur 70€' },
      { label: '', reduction: '' },
      { label: '29€ remboursés', reduction: 'sur 85€' },
      { label: '34€ remboursés', reduction: 'sur 100€' },
      { label: '', reduction: '' },
      { label: '39€ remboursés', reduction: 'sur 110€' },
      { label: '44€ remboursés', reduction: 'sur 130€' },
    ],
    hearingScore: 4,
    hearingDropdown: [
      { label: '19€ remboursés', reduction: 'sur 60€' },
      { label: '24€ remboursés', reduction: 'sur 80€' },
    ],
    includedServices: [
      'Assistance',
      'Réseau de soins',
      'Informations de santé et de prévention',
      'Offres privilèges',
      'Téléconsultation',
    ],
    includedBenetifs: [
      '10% de remise pour les sportifs',
      '1 mois offert sur votre cotisation santé',
    ],
    documents: [
      {
        title: 'Grille des garanties',
        url: 'https://www.google.com',
      },
      {
        title: 'Exemples de remboursement',
        url: 'https://www.google.com',
      },
      {
        title: 'Services inclus',
        url: 'https://www.google.com',
      }
    ],
    promoted: false,
  },
  {
    code: '123459',
    id: 4,
    name: 'Réflex Option',
    price: 81,
    dentalScore: 4,
    dentalDropdown: [
      { label: '16€ remboursés', reduction: 'sur 55€' },
      { label: '', reduction: '' },
      { label: '26€ remboursés', reduction: 'sur 75€' },
      { label: '31€ remboursés', reduction: 'sur 90€' },
      { label: '36€ remboursés', reduction: 'sur 105€' },
    ],
    opticalScore: 4,
    opticalDropdown: [
      { label: '22€ remboursés', reduction: 'sur 65€' },
      { label: '27€ remboursés', reduction: 'sur 85€' },
    ],
    hospitalizationScore: 4,
    hospitalizationDropdown: [
      { label: '65€ remboursés', reduction: 'sur 230€' },
      { label: '75€ remboursés', reduction: 'sur 250€' },
      { label: '', reduction: '' },
      { label: '85€ remboursés', reduction: 'sur 280€' },
      { label: '95€ remboursés', reduction: 'sur 330€' },
    ],
    standardCareScore: 4,
    standardCareDropdown: [
      { label: '', reduction: '' },
      { label: '21€ remboursés', reduction: 'sur 65€' },
      { label: '26€ remboursés', reduction: 'sur 75€' },
      { label: '', reduction: '' },
      { label: '31€ remboursés', reduction: 'sur 90€' },
      { label: '36€ remboursés', reduction: 'sur 105€' },
      { label: '', reduction: '' },
      { label: '41€ remboursés', reduction: 'sur 115€' },
      { label: '46€ remboursés', reduction: 'sur 135€' },
    ],
    hearingScore: 4,
    hearingDropdown: [
      { label: '21€ remboursés', reduction: 'sur 65€' },
      { label: '26€ remboursés', reduction: 'sur 85€' },
    ],
    includedServices: [
      'Assistance',
      'Réseau de soins',
      'Informations de santé et de prévention',
      'Offres privilèges',
      'Téléconsultation',
    ],
    includedBenetifs: [
      '10% de remise pour les sportifs',
      '1 mois offert sur votre cotisation santé',
    ],
    documents: [
      {
        title: 'Grille des garanties',
        url: 'https://www.google.com',
      },
      {
        title: 'Exemples de remboursement',
        url: 'https://www.google.com',
      },
      {
        title: 'Services inclus',
        url: 'https://www.google.com',
      }
    ],
    promoted: false,
  },
];

const categories = [
  {
    label: 'Dentaire',
    icon: <Dental />,
    key: 'dental',
    contents: [
      'Détartrage',
      'Couronne dentaire céramo-métallique sur :',
      [
        'Incisives, canines et 1ères prémolaires "100 % Santé"',
        'Deuxièmes prémolaires "tarif maîtrisé"',
        'Molaires "tarif libre"',
      ]
    ]
  },
  {
    label: 'Optique',
    icon: <Optic />,
    key: 'optical',
    contents: [
      '"100 % Santé" (monture + 2 verres simples)',
      'Hors "100 % Santé" (monture + 2 verres simples)',
    ]
  },
  {
    label: 'Hospitalisation',
    icon: <Hospitalization />,
    key: 'hospitalization',
    contents: [
      'Forfait journalier hospitalier',
      'Forfait Actes Lourds',
      'Honoraires :',
      [
        'Anesthésiste (adhérent aux pratiques tarifaires maîtrisées)',
        'Chirurgien (non adhérent aux pratiques tarifaires maîtrisées)'
      ]
    ]
  },
  {
    label: 'Soins courants',
    icon: <CurrentCare />,
    key: 'currentCare',
    contents: [
      'Honoraires Médecins généralistes(consultations, visites) :',
      [
        'Médecin adhérent aux pratiques tarifaires maîtrisées',
        'Médecin non adhérent aux pratiques tarifaires maîtrisées',
      ],
      'Honoraires Médecins spécialistes(consultations, visites) :',
      [
        'Médecin adhérent aux pratiques tarifaires maîtrisées',
        'Médecin non adhérent aux pratiques tarifaires maîtrisées',
      ],
      'Matériel Médical :',
      [
        'Petit appareillage(exemple : 2 semelles orthopédiques)'
      ],
      'Séance de kinésithérapeute',
    ]
  },
  {
    label: 'Audition',
    icon: <Hearing />,
    key: 'hearing',
    contents: [
      '+ de 20 ans "100 % Santé" par oreille',
      '+ de 20 ans "hors 100 % Santé" par oreille'
    ]
  },
  {
    label: 'Services inclus',
    icon: <Ring />,
    key: 'services',
  },
  {
    label: 'Avantages**',
    icon: <Gift />,
    key: 'advantages',
  },
  {
    label: 'Documents à télécharger',
    icon: <Download />,
    key: 'documents',
  }
];

const sportValues = [
  { l: 'Oui', v: 'true' },
  { l: 'Non', v: 'false' }
];

const guarantees = [
  {
    id: 1,
    title: 'Primo',
    price: 32.34
  },
  {
    id: 2,
    title: 'Reflex',
    price: 57.50
  },
  {
    id: 3,
    title: 'Déclic',
    price: 77.34
  },
  {
    id: 4,
    title: 'Tonic',
    price: 92.34
  },
  {
    id: 5,
    title: 'Oxygène',
    price: 94.34
  },
  {
    id: 6,
    title: 'Oxygène +',
    price: 109.34
  },
  {
    id: 7,
    title: 'Zénith',
    price: 118.34
  },
  {
    id: 8,
    title: 'Zénith +',
    price: 150.34
  }
];

const socialSecurityConnectionValues = [
  { l: 'Rattachement adhérent', v: 'yes' },
  { l: 'Autre rattachement', v: 'other' },
];

const jobCategories = [
  { l: 'Auto Entrepreneur', v: 'autoEntrepreneur' },
  { l: 'Agriculteur', v: 'farmer' },
  { l: 'Apprentissage', v: 'apprenticeship' },
  { l: 'Contrat Qualification', v: 'qualificationContract' },
  { l: 'Demandeur D\'emploi', v: 'jobSeeker' },
  { l: 'Étudiant', v: 'student' },
  { l: 'Sans Activité', v: 'noActivity' },
  { l: 'Retraité', v: 'retired' },
  { l: 'Salarié', v: 'employee' }
];

export {
  socialSecurityRegimeValues,
  activityTypeValues,
  spouseActivityTypeValues,
  stateValues,
  situationValues,
  devisData,
  categories,
  sportValues,
  guarantees,
  socialSecurityConnectionValues,
  jobCategories
}