import React from 'react';

import useDevis from 'src/hooks/useDevis';
import AdditionalOffers from 'src/components/Ui/AdditionalOffers';
import NextButton from "src/components/Ui/NextButton";
import Info from "src/assets/Info";
import ToolTip from 'src/assets/ToolTip';
import TinyStar from 'src/assets/Stars/Tiny';
import { formDevisSpouseInformationsInvalid } from 'src/services/validatorsDevis';
import { SituationState } from 'src/types/Situation';
import Input from 'src/components/Ui/Input';
import Select from 'src/components/Ui/Select';
import { isBirthdayValid, getMaxDateBirthday } from "src/services/dateUtils";

import styles from './styles.module.css';
import SpouseModal from 'src/components/Ui/Modals/SpouseModal';

const SpouseSituation = () => {
  const context = useDevis();

  const { situationState, setSituationState, setStep, situationStep, maxSteps, setSituationStep, spouseSocialSecuritySchemes, spouseActivityValues } = context;

  const [modal, setModal] = React.useState(false);

  return (
    <div className={`flex justify-center p-4 gap-12 rounded-md items-start ${styles.responsivContainer}`}>
      <div className={`flex flex-col items-start space-y-4 p-14 relative overflow-hidden ${styles.section}`}>
        <span className={styles.iconInfo}>
          <Info />
        </span>
        <span className={styles.steps}>
          {situationStep}/{maxSteps}
        </span>
        <h1 className="text-xl mb-4 font-bold text-blue-900">J’indique la situation de mon conjoint</h1>
        <Input
          value={situationState.spouse.birthday}
          onChange={(birthday) =>
            setSituationState((prevState: SituationState) => ({
              ...prevState,
              spouse: {
                ...prevState.spouse,
                birthday: birthday as string,
              }
            }))
          }
          type='date'
          id='birthday'
          error={isBirthdayValid(situationState.spouse.birthday)}
          maxDate={getMaxDateBirthday()}
          label='Date de naissance *'
          maxLength={10}
        />
        <Select
          label='Régime obligatoire *'
          value={situationState.spouse.socialSecurityScheme}
          error={situationState.spouse.socialSecurityScheme === ''}
          onChange={(socialSecurityScheme) =>
            setSituationState((prevState: SituationState) => ({
              ...prevState,
              spouse: {
                ...prevState.spouse,
                socialSecurityScheme: socialSecurityScheme,
              }
            }))
          }
          values={spouseSocialSecuritySchemes.map(({ id, name }) => ({ v: id, l: name }))}
          id='socialSecurityScheme'
        />
        <Select
          label='Type d’activité *'
          value={situationState.spouse.activity}
          onChange={(activity) =>
            setSituationState((prevState: SituationState) => ({
              ...prevState,
              spouse: {
                ...prevState.spouse,
                activity: activity,
              }
            }))
          }
          values={spouseActivityValues.map(({ id, name }) => ({ v: id, l: name }))}
          id='activity'
        />
        <Select
          label='Sportif (-10%) *'
          value={situationState.spouse.athletic}
          error={situationState.spouse.athletic === ''}
          onChange={(athletic) =>
            setSituationState((prevState: SituationState) => ({
              ...prevState,
              spouse: {
                ...prevState.spouse,
                athletic: athletic,
              }
            }))
          }
          values={[
            { l: 'Oui', v: 'true' },
            { l: 'Non', v: 'false' }
          ]}
          id='athletic'
          noPlaceholder
        />
        <div className="flex items-center gap-6">
          <span className={styles.tooltip}>
            <ToolTip />
          </span>
          <p className={`text-justify ${styles.informations}`}>
            Un justificatif d’inscription à un club de sport devra être fourni au moment de la souscription pour bénéficier de la réduction.
            <span
              onClick={() => setModal(true)}
              className={styles.informationsCondition}>Voir les conditions</span>
          </p>
        </div>
        <div className='flex justify-end w-full'>
          <p style={{ color: '#243E69', fontSize: '12px', fontWeight: 400 }}>(*) : champs obligatoires</p>
        </div>
      </div>

      <AdditionalOffers
        image="/images/my-situation-offer.jpg"
        additionalComponent={(
          <div className={styles.reduction}>
            <div className='relative'>
              <TinyStar />
              <span className={styles.reductionText}>-10%</span>
            </div>
          </div>
        )}
        title="Membre d’un club de sports ?"
        description="Bénéficiez de -10% sur vos cotisations !"
        action={() => {
          setModal(true);
        }}
      />

      {
        modal && (
          <SpouseModal
            close={() => setModal(false)}
          />
        )
      }

      <NextButton
        canNext={!formDevisSpouseInformationsInvalid({ situationState })}
        canPrev={true}
        nextStep={() => maxSteps === 2 ? setStep(1) : setSituationStep(3)}
        prevStep={() => { setSituationStep(1) }}
      />
    </div >
  )
}

export default SpouseSituation;