import { useState, useLayoutEffect } from "react"
import Advise from "src/assets/Advise";

import styles from './styles.module.css';

const ContactButton = () => {
    const [hover, setHover] = useState(false);
    const [isMobile, setIsMobile] = useState(false);

    const onHover = () => {
        setHover(!hover);
    }

    useLayoutEffect(() => {
        function updateSize() {
            setIsMobile(window.innerWidth < 768);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    return (
        <a href="tel:0494185510"
          onMouseEnter={onHover}
          onMouseLeave={onHover}
          className={`bg-white py-2 px-4 flex flex-row items-center justify-between gap-2 absolute right-4 ${styles.contact}`}>
            {!isMobile && <span>{hover ? '04 94 18 55 10' : 'Contacter un conseiller'}</span>}
          <Advise />
        </a>
    );
}

export default ContactButton;