import React, { useEffect, useRef } from 'react';

import Logo from 'src/assets/Logo';
import NavCard from 'src/components/Ui/NavCard';

import styles from './styles.module.css';
import ContactButton from 'src/components/Ui/ContactButton';

interface BaseLayoutProps {
  title: string;
  subTitle?: string;
  steps: { title: string | null, component: JSX.Element }[];
  setStep: React.Dispatch<React.SetStateAction<number>>;
  step: number;
}

const BaseLayout = ({ title, subTitle, steps, step }: BaseLayoutProps) => {
  const navRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (navRef.current) {
      navRef.current.scrollTo({ left: 100 * step, behavior: 'smooth' });
    }
  }, [step])

  return (
    <div className='max-w-full overflow-hidden'>
      <header className={`text-white p-4 flex justify-between items-center ${styles.header}`}>
        <button
          onClick={() => {
            window.open('https://www.mutuelleverte.com', '_blank');
          }}
          className={`bg-transparent p-4 rounded border-1 border-white absolute left-4 ${styles.back_button}`}>
          Retour au site
        </button>
        <div className='m-auto'>
          <Logo />
        </div>
        <ContactButton />
      </header>

      <div
        style={{ backgroundImage: 'url(/images/ellipse.png)' }}
        className={styles.bgHeader}
      />
      <div className="h-32 flex flex-col items-center justify-center">
        <h1 className={`text-center text-white text-5xl font-bold relative z-10 ${styles.title}`}>{title}</h1>
        {subTitle && <h2 className={`text-center text-white text-2xl relative z-10 ${styles.subtitle}`}>{subTitle}</h2>}
      </div>

      <main className="flex flex-col mx-auto rounded mb-8 relative z-10">
        <div className={`overflow-x-scroll ${styles.scrollContainer}`} ref={navRef}>
          <nav className={`flex self-center bg-white justify-around mb-8 shadow-xl relative z-10 ${styles.nav}`}>
            {steps.map((s, index) => (
              s.title && <NavCard
                key={s.title}
                title={s.title}
                step={step}
                index={index}
              />
            ))}
          </nav>
        </div>
        {steps[step].component}
      </main>

      <footer className={`text-white p-4 text-center flex flex-col md:flex-row justify-around py-16 ${styles.footer}`}>
        <a href="https://www.adveris.fr/mentions-legales/" target='_blank' rel="noreferrer" className="underline my-2">Mentions légales</a>
        <a href="https://www.adveris.fr/wp-content/uploads/2024/05/CGV-Adveris.pdf" rel="noreferrer" target='_blank' className="underline my-2">Politique de confidentialité</a>
        <p className='my-2'>©{new Date().getFullYear()} La Mutuelle Verte</p>
      </footer>
      <div className={styles.paddingBottom} />
    </div>
  )
}

export default BaseLayout;