import React from 'react';

import useSubscription from 'src/hooks/useSubscription';
import { devisData } from 'src/data/DevisData';
import DevisResume from 'src/components/Devis/Resume';
import NextButton from 'src/components/Ui/NextButton';
import SignatureDocument from './Document';
import SignatureConfirm from './Confirm';
import OffersModal from 'src/components/Ui/Modals/OffersModal';

import styles from './styles.module.css';

const Signature = () => {
  const [modal, setModal] = React.useState(false);
  const context = useSubscription();

  const { setStep, offer, setSignature, signature } = context;

  const [documentsStep, setDocumentsSteps] = React.useState(0);
  const devis = devisData.find((devis) => devis.id === offer);

  return (
    <div className={`flex justify-center p-4 gap-12 rounded-md ${styles.responsivContainer}`}>
      <div className={`flex flex-col items-start space-y-4 relative overflow-hidden gap-12 m-auto md:m-0 ${styles.section}`}>
        {documentsStep === 0 && (
          <SignatureDocument
            signature={signature.subscription}
            setSignature={(value: string) => {
              setSignature({
                ...signature,
                subscription: value,
              });
            }}
            title='Signer le bulletin d’adhésion'
            counter={1}
          />
        )}
        {documentsStep === 1 && (
          <SignatureDocument
            signature={signature.mandate}
            setSignature={(value: string) => {
              setSignature({
                ...signature,
                mandate: value,
              });
            }}
            title='Signer le mandat de prélèvement'
            counter={2}
          />
        )}
        {documentsStep === 2 && (
          <SignatureConfirm price={devis?.price || 0} setStep={setStep} />
        )}
      </div>

      {devis &&
        <div className="flex flex-col">
          <DevisResume devis={devis} withoutSubscribeButton actionConditions={() => setModal(true)} />
        </div>
      }

      {documentsStep !== 2 && (
        <NextButton
          canPrev={true}
          canNext={
            documentsStep === 0
              ? !!signature.subscription
              : documentsStep === 1
                ? !!signature.mandate
                : true
          }
          nextStep={() => {
            setDocumentsSteps(documentsStep + 1);
          }}
          prevStep={() => {
            if (documentsStep > 0) {
              setDocumentsSteps(documentsStep - 1);
            } else {
              setStep(3);
            }
          }}
        />
      )}
      {
        modal && (
          <OffersModal
            close={() => setModal(false)}
            isAthletic={'true'}
          />
        )
      }

    </div>
  )
}

export default Signature;