import useDevis from 'src/hooks/useDevis';
import Situation from 'src/components/Devis/Situation';
import Needed from 'src/components/Devis/Needed';
import Offers from 'src/components/Devis/Offers';
import Informations from 'src/components/Devis/Informations';
import Confirmation from 'src/components/Devis/Confirmation';

import BaseLayout from 'src/layout/BaseLayout';

const DevisForm = () => {
  const context = useDevis();

  const { step, setStep } = context;

  const steps = [
    {
      title: 'Ma situation',
      component: <Situation />
    },
    {
      title: 'Mes besoins',
      component: <Needed />
    },
    {
      title: 'Mes offres',
      component: <Offers />
    },
    {
      title: 'Mon devis',
      component: <Informations />
    },
    {
      title: null,
      component: <Confirmation />
    }
  ];

  return (
    <BaseLayout
      title="Mon devis personnalisé"
      subTitle="Obtenez instantanément votre devis en ligne"
      steps={steps}
      setStep={setStep}
      step={step}
    />
  );
}

export default DevisForm;
