import {
  SituationState,
  PersonalInformations,
  SubChildrenInformations
} from "src/types/Situation";

import { isDateValid, isAccessionDateValid, adhHasMajority, isBirthdayValid } from "src/services/dateUtils";
import { isEmailValid } from "src/services/emailUtils";

const formDevisMyInformationsInvalid = ({
  situationState
}: {
  situationState: SituationState;
}) => {
  if (situationState.outside === '0' && situationState.postalCode.length !== 5) {
    return true;
  }

  if (
    !situationState.outside ||
    !situationState.currentState || situationState.currentState.length > 20 ||
    !isBirthdayValid(situationState.birthday) ||
    !isDateValid(situationState.accessionDate) || situationState.accessionDate.length !== 10 ||
    !isAccessionDateValid(situationState.accessionDate) ||
    !situationState.situation || situationState.situation.length > 4 ||
    !situationState.socialSecurityScheme || situationState.socialSecurityScheme.length > 2 ||
    !situationState.activity || situationState.activity.length > 2
  ) {
    return true;
  }

  return false;
}

const formDevisSpouseInformationsInvalid = ({
  situationState
}: {
  situationState: SituationState;
}) => {
  if (
    !isBirthdayValid(situationState.spouse.birthday) ||
    !situationState.spouse.birthday || situationState.spouse.birthday.length > 10 ||
    !situationState.spouse.socialSecurityScheme || situationState.spouse.socialSecurityScheme.length > 2 ||
    !situationState.spouse.activity || situationState.spouse.activity.length > 2 ||
    !adhHasMajority(situationState.spouse.birthday)
  ) {
    return true;
  }

  return false;
}

const formDevisChildrenInformationsInvalid = (children: SubChildrenInformations[]) => {
  if (children.length === 0) {
    return true;
  }

  for (const child of children) {
    if (!child.birthday || !isDateValid(child.birthday) || child.birthday.length > 10) {
      return true;
    }
  }

  return false;
}

const formDevisInformationsInvalid = ({
  personalInformations,
  situationState,
}: {
  personalInformations: PersonalInformations,
  situationState: SituationState
}) => (
  !personalInformations.civility || personalInformations.civility.length > 1 ||
  !personalInformations.firstname || personalInformations.firstname.length > 15 ||
  !personalInformations.lastname || personalInformations.lastname.length > 25 ||
  !personalInformations.email || personalInformations.email.length > 50 ||
  !isEmailValid(personalInformations.email) ||
  !personalInformations.streetName || personalInformations.streetName.length > 27 ||
 ( personalInformations.streetNumber !== '' && personalInformations.streetNumber.length > 4) ||
  (personalInformations.streetMore && personalInformations.streetMore.length > 32) ||
  !situationState.postalCode || situationState.postalCode.length !== 5 ||
  !personalInformations.city || personalInformations.city.length > 26 ||
  !personalInformations.country || personalInformations.country.length > 50 ||
  ('0' === situationState.outside && (!personalInformations.phonePrefix || personalInformations.phonePrefix.length > 3)) ||
  !personalInformations.phone || personalInformations.phone.length !== 10
)

export {
  formDevisMyInformationsInvalid,
  formDevisSpouseInformationsInvalid,
  formDevisChildrenInformationsInvalid,
  formDevisInformationsInvalid,
}