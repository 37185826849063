import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import { SubscriptionProvider } from './context/SubscriptionContext';
import DevisForm from './views/DevisForm';
import SubscriptionForm from './views/SubscriptionForm';
import Error404 from './views/404';
import reportWebVitals from './reportWebVitals';

import { getStep } from "./services/api-subscription-steps";

import './index.css';
import "@fontsource/kumbh-sans";

import DevisLayout from './layout/DevisLayout';

// Define the routes using createBrowserRouter
const router = createBrowserRouter([
  {
    path: '/',
    element: <DevisLayout />,
    children: [
      {
        path: '',
        element: <DevisForm />
      },
      {
        path: 'subscription/:quoteNumber',
        element: (
          <SubscriptionProvider>
            <SubscriptionForm />
          </SubscriptionProvider>
        ),
        loader: async ({ params }) => {
          if (!params.quoteNumber) return null;

          const currentStep = await getStep(params.quoteNumber);

          return {
            step: currentStep?.step || 1,
            quoteNumber: params.quoteNumber
          };
        },
      },
    ],
  },
  {
    path: '*',
    element: <Error404 />,
  },
]);

// Render the router with RouterProvider
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// Web vitals reporting (optional)
reportWebVitals();
