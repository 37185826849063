import { Devis, SituationState, UserInformations } from "src/types/Situation";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

async function getSituations(outside: string) {
    const response = await fetch(`${API_BASE_URL}/comparateur/ajax/getsituation?outside=${outside}`);
    const data = await response.json();

    return data;
}

async function getSocialSecuritySchemes(outside: string, situation: string) {
    const response = await fetch(`${API_BASE_URL}/comparateur/ajax/getsocialsecurityscheme?outside=${outside}&situation=${situation}`);
    const data = await response.json();

    return data;
}

async function getActivities(outside: string, situation: string, socialSecurityRegime: string, type: string, activity?: string) {
    const response = await fetch(`${API_BASE_URL}/comparateur/ajax/getactivity?outside=${outside}&situation=${situation}&socialsecurityscheme=${socialSecurityRegime}&type=${type}&activity=${activity || ''}`);
    const data = await response.json();

    return data;
}

async function getProductPricing(situationState: SituationState, totalScore: number, minAge?: number, maxAge?: number) {
    const response = await fetch(`${API_BASE_URL}/devis/getproductpricing`, {
        method: 'POST',
        body: JSON.stringify({'userInfo': situationState, 'totalScore': totalScore, 'minAge': minAge, 'maxAge': maxAge}),
        headers: {
            'Content-Type': 'application/json'
        }
    });

    const data = await response.json();

    return data;
}

async function getQuotePriceYear() {
    const response = await fetch(`${API_BASE_URL}/api/config/quote-price-year`);

    const data = await response.json();

    return data;
}

async function submitForm(userInformations: UserInformations, offers: Devis[]) {
    if (0 === offers.length) {
        return;
    }

    const response = await fetch(`${API_BASE_URL}/devis/email/`, {
        method: 'POST',
        body: JSON.stringify({'userInfo': userInformations, 'products': offers}),
        headers: {
            'Content-Type': 'application/json'
        }
    });

    const data = await response.json();

    return data;
}

async function getCitiesByPostalCode(postalCode: string) {
    const response = await fetch(`${API_BASE_URL}/ajax/city/${postalCode}`);

    const data = await response.json();

    return data;
}

async function getRegisteredQuote(quoteId: string) {
    const response = await fetch(`${API_BASE_URL}/souscription/find/I${quoteId}`);

    const data = await response.json();

    return data;
}

export {
    API_BASE_URL,
    getSituations,
    getSocialSecuritySchemes,
    getActivities,
    getProductPricing,
    getQuotePriceYear,
    submitForm,
    getCitiesByPostalCode,
    getRegisteredQuote
};
