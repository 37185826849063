import { QuoteData } from "src/types/Situation";
import { API_BASE_URL } from "./api";

export async function getStep(number: string) {
    const response = await fetch(`${API_BASE_URL}/souscription/procedure/${number}/get-step`);

    const data = await response.json();

    return data;
}

export async function setSubscriptionStep(step: number, quoteNumber: string, quoteData?: QuoteData) {
    const response = await fetch(`${API_BASE_URL}/souscription/procedure/${quoteNumber}/set-step/${step}`, {
        method: 'POST',
        body: JSON.stringify({'quote': quoteData}),
        headers: {
            'Content-Type': 'application/json'
        }
    });

    const data = await response.json();

    return data;
}