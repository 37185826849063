import Spouse from 'src/assets/Spouse';
import Input from 'src/components/Ui/Input';
import Select from 'src/components/Ui/Select';
import useSubscription from 'src/hooks/useSubscription';

import styles from './styles.module.css';
import { getMaxDateBirthday, formatDate } from 'src/services/dateUtils';

const SpouseInformation = () => {
  const subscriptionContext = useSubscription();
  const { spouseActivity, quoteData, setQuoteDataSpouseInformation } = subscriptionContext;

  return (
  <div className={styles.section}>
    <span className={styles.iconInfo}>
      <Spouse />
    </span>
    <h1 className={styles.titleSection}>Votre conjoint</h1>
    <label htmlFor="civility" className={`flex items-center ${styles.label}`}>
      Civilité *
    </label>
    <div className="flex flex-row gap-4">
      <button
        className={`${styles.button} ${quoteData.spouse.civility === '2' ? styles.activ : ''}`}
        onClick={() => {
          setQuoteDataSpouseInformation({ civility: '2' })
        }
        }>Madame</button>
      <button
        className={`${styles.button} ${quoteData.spouse.civility === '1' ? styles.activ : ''}`}
        onClick={() => {
          setQuoteDataSpouseInformation({ civility: '1' })
        }
        }>Monsieur</button>
    </div>
    <Input
      label='Nom *'
      value={quoteData.spouse?.lastname ?? ''}
      error={quoteData.spouse.lastname === ''}
      onChange={(lastname) =>
        setQuoteDataSpouseInformation({ lastname: lastname as string})
      }
      id='lastname'
    />
    <Input
      label='Prénom *'
      value={quoteData.spouse?.firstname ?? ''}
      error={quoteData.spouse.firstname === ''}
      onChange={(firstname) =>
        setQuoteDataSpouseInformation({ firstname: firstname as string})
      }
      id='firstname'
    />
    <Input
      label='Date de naissance *'
      value={formatDate(quoteData.spouse.birthday)}
      error={quoteData.spouse.birthday === ''}
      maxDate={getMaxDateBirthday()}
      onChange={() => {}}
      id='birthday'
      type='date'
    />
    <Select
      label='Rattachement *'
      value={quoteData.spouse.socialSecurityConnection}
      onChange={() => {}}
      values={[
        { l: 'Rattachement adhérent', v: 'yes' },
        { l: 'Autre rattachement', v: 'other' },
      ]}
      noPlaceholder
      id='socialSecurityConnection'
    />
    {quoteData.spouse.socialSecurityConnection === 'other' && (
      <Input
        label='Numéro de sécurité sociale *'
        value={quoteData.spouse?.insee ?? ''}
        onChange={(insee) =>
          setQuoteDataSpouseInformation({ insee: insee as string})
        }
        id='inseeSpouse'
        maxLength={15}
      />
    )}
    <Select
      label='Profession *'
      value={spouseActivity.id}
      onChange={(activity) =>
        setQuoteDataSpouseInformation({ activity: activity as string})
      }
      values={[{
        v: spouseActivity.id,
        l: spouseActivity.name
      }]}
      id='activity'
    />
    <Select
      label='Sportif.ve ? Bénéficiez de 10% de réduction sur vos cotisations santé*'
      id='sport'
      value={quoteData.spouse?.athletic ?? 'false'}
      onChange={(athletic) => {
        setQuoteDataSpouseInformation({ athletic: athletic as string})
      }}
      values={[
        { l: 'Oui', v: 'true' },
        { l: 'Non', v: 'false' }
      ]}
      noPlaceholder
    />
  </div>
)}

export default SpouseInformation;