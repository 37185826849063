import Child from 'src/assets/Child';
import Input from 'src/components/Ui/Input';
import Select from 'src/components/Ui/Select';
import { socialSecurityConnectionValues } from 'src/data/DevisData';

import styles from './styles.module.css';
import useSubscription from 'src/hooks/useSubscription';

const ChildrenInformation = () => {
  const subscriptionContext = useSubscription();
  const { quoteData, setQuoteDataChildInformation } = subscriptionContext;
  return (
  <div className={styles.section}>
    <span className={styles.iconInfo}>
      <Child />
    </span>
    <h1 className={styles.titleSection}>Vos enfants</h1>
    {
      quoteData.children.map((child, index) => (
        <div className={styles.childContainer} key={'child' + (index + 1)}>
          <p className={styles.childTitle}>Enfant {index + 1}</p>
          <label htmlFor="civility" className={`flex items-center ${styles.label}`}>
            Civilité *
          </label>
          <div className="flex flex-row gap-4">
            <button
              className={`${styles.button} ${child.civility === '2' ? styles.activ : ''}`}
              onClick={() => {
                setQuoteDataChildInformation({ civility: '2' }, index);
              }
              }>Madame</button>
            <button
              className={`${styles.button} ${child.civility === '1' ? styles.activ : ''}`}
              onClick={() => {
                setQuoteDataChildInformation({ civility: '1' }, index);
              }
              }>Monsieur</button>
          </div>
          <Input
            label='Nom *'
            value={child.lastname}
            onChange={(lastname) => {
              setQuoteDataChildInformation({ lastname: lastname as string }, index);
            }}
            id='lastname'
          />
          <Input
            label='Prénom *'
            value={child.firstname}
            onChange={(firstname) => {
              setQuoteDataChildInformation({ firstname: firstname as string }, index);
            }}
            id='firstname'
          />
          <Input
            label='Date de naissance *'
            value={child.birthday}
            onChange={(birthday) => {
              setQuoteDataChildInformation({ birthday: birthday as string }, index);
            }}
            id='birthdate'
            type='date'
            maxLength={10}
            maxDate={new Date().toISOString().split('T')[0]}
          />
          <Select
            label='Rattachement *'
            value={child.socialSecurityConnection}
            onChange={(socialSecurityConnection) => {
              setQuoteDataChildInformation({
                socialSecurityConnection: socialSecurityConnection
              }, index);
              if (socialSecurityConnection !== 'other') {
                setQuoteDataChildInformation({
                  insee: '' as string,
                }, index);
              }
            }}
            values={socialSecurityConnectionValues}
            id='socialSecurityConnection'
          />
          {child.socialSecurityConnection === 'other' && (
            <Input
              label='Numéro de sécurité sociale *'
              value={child.insee}
              onChange={(insee) => {
                setQuoteDataChildInformation({ insee: insee as string }, index);
              }}
              id='insee'
              maxLength={15}
            />
          )}
        </div>
      ))
    }
  </div>
)
}

export default ChildrenInformation;