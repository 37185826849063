import Info from 'src/assets/Info';
import { countries, phonePrefix } from 'src/data/Form';
import Input from 'src/components/Ui/Input';
import Select from 'src/components/Ui/Select';
import InfoPicto from 'src/assets/InfoPicto';
import Checkbox from 'src/assets/CheckBox';
import { formatDate } from 'src/services/dateUtils';

import styles from './styles.module.css';
import useSubscription from 'src/hooks/useSubscription';

interface MyInformationProps {
  handleModal: () => void;
}

const MyInformations = ({
  handleModal,
}: MyInformationProps) => {
  const subscriptionContext = useSubscription();
  const { activity, cities, quoteData, setQuoteDataPersonnalInformation } = subscriptionContext;

  return (
    <div className={styles.section}>
      <span className={styles.iconInfo}>
        <Info />
      </span>
      <h1 className={`m-auto md:m-0 ${styles.titleSection}`}>Vous</h1>
      <label htmlFor="civility" className={`flex items-center ${styles.label}`}>
        Civilité *
      </label>
      <div className="flex flex-row gap-4">
        <button
          className={`${styles.button} ${quoteData.member.civility === 2 ? styles.activ : ''}`}
          onClick={() => {
            setQuoteDataPersonnalInformation({ civility: '2' })
          }}>Madame</button>
        <button
          className={`${styles.button} ${quoteData.member.civility === 1 ? styles.activ : ''}`}
          onClick={() => {
            setQuoteDataPersonnalInformation({ civility: '1' })
          }}>Monsieur</button>
      </div>
      <Input
        label='Nom *'
        value={quoteData.member?.lastname ?? ''}
        onChange={(lastname) =>
          setQuoteDataPersonnalInformation({ lastname: lastname as string })
        }
        id='lastname'
        maxLength={25}
      />
      <Input
        label='Prénom *'
        value={quoteData.member?.firstname ?? ''}
        onChange={(firstname) =>
          setQuoteDataPersonnalInformation({ firstname: firstname as string })
        }
        id='firstname'
        maxLength={15}
      />
      <Input
        label='Date de naissance *'
        value={formatDate(quoteData.member.birthday)}
        onChange={(birthday) => 
          setQuoteDataPersonnalInformation({ birthday: formatDate(String(birthday)) })
        }
        id='birthday'
        type='date'
        disabled={quoteData.member.birthday !== '' ? true : false}
        maxLength={10}
      />
      <Input
        label="Numéro de rue"
        value={quoteData.member?.streetNumber ?? ''}
        onChange={(streetNumber) =>
          setQuoteDataPersonnalInformation({ streetNumber: streetNumber as string })
        }
        id='streetNumber'
        maxLength={4}
      />
      <Input
        label='Adresse *'
        value={quoteData.member?.streetName ?? ''}
        onChange={(streetName) =>
          setQuoteDataPersonnalInformation({ streetName: streetName as string })
        }
        id='streetName'
        maxLength={27}
      />
      <Input
        label="Complément d’adresse"
        value={quoteData.member?.streetMore ?? ''}
        onChange={(streetMore) =>
          setQuoteDataPersonnalInformation({ streetMore: streetMore as string })
        }
        id='streetMore'
        maxLength={32}
      />
      <Input
        label='Code postal *'
        value={quoteData.member.postalCode}
        onChange={(postalCode) =>
          quoteData.member.postalCode !== '' && setQuoteDataPersonnalInformation({ postalCode: postalCode as string })
        }
        id='postalCode'
        disabled={quoteData.member.postalCode !== '' && quoteData.member.postalCode.length > 5 ? true : false}
        maxLength={5}
      />
      <Select
        label={'Ville *'}
        value={quoteData.member.city}
        onChange={(city) =>
          setQuoteDataPersonnalInformation({ city: city as string })
        }
        id='city'
        values={cities.map(({ key, value }) => ({ v: key, l: value }))}
      />
      <Select
        label='Pays *'
        value={quoteData.member?.country ?? ''}
        onChange={(country) =>
          setQuoteDataPersonnalInformation({ country: country as string })
        }
        values={countries.map(({ value, label }) => ({ v: value, l: label }))}
        id='country'
      />
      <Input
        label='Email *'
        value={quoteData.member.email}
        onChange={(email) =>
          setQuoteDataPersonnalInformation({ email: email as string })
        }
        id='email'
        type='email'
        maxLength={50}
      />
      <div className="flex items-end gap-4 w-full">
        <div className={styles.selectBox}>
          <Select
            label='Téléphone portable *'
            value={quoteData.member.phonePrefix}
            onChange={(phonePrefix) => {
              setQuoteDataPersonnalInformation({ phonePrefix: phonePrefix as string })
            }}
            id="phonePrefix"
            values={phonePrefix.map((prefix) => ({ v: prefix.value, l: prefix.label }))}
          />
        </div>
        <Input
          value={quoteData.member.phone}
          onChange={(phone) =>
            setQuoteDataPersonnalInformation({ phone: phone as string })
          }
          id='phone'
          maxLength={10}
        />
      </div>
      <div className="flex items-end gap-4 w-full">
        <div className={styles.selectBox}>
          <Select
            label='Téléphone fixe'
            value={quoteData.member.phoneFixPrefix}
            onChange={(phoneFixPrefix) => {
              setQuoteDataPersonnalInformation({ phoneFixPrefix: phoneFixPrefix as string })
            }}
            id="phonePrefix"
            values={phonePrefix.map((prefix) => ({ v: prefix.value, l: prefix.label }))}
          />
        </div>
        <Input
          value={quoteData.member?.phoneFix ?? ''}
          onChange={(phoneFix) =>
            setQuoteDataPersonnalInformation({ phoneFix: phoneFix as string })
          }
          id='phoneFix'
          maxLength={10}
        />
      </div>

      <Input
        label='Numéro de sécurité sociale *'
        value={quoteData.member?.insee ?? ''}
        onChange={(insee) => {
          setQuoteDataPersonnalInformation({ insee: insee as string })
        }}
        maxLength={15}
        id='socialNumber'
        />
      <Input
        label='Profession *'
        value={activity.name}
        onChange={() => {}}
        maxLength={20}
        id='activity'
      />
      <Select
        label='Vous êtes sportif ? Bénéficiez de 10% de réduction sur vos cotisations santé*'
        value={quoteData.member?.athletic ?? 'false'}
        onChange={(athletic) => {
          setQuoteDataPersonnalInformation({ athletic })
        }}
        noPlaceholder
        values={[
          { l: 'Oui', v: 'true' },
          { l: 'Non', v: 'false' }
        ]}
        id='sport'
      />
      <div className={styles.advise}>
        <span style={{ height: '24px', width: '24px' }}>
          <InfoPicto />
        </span>
        <span className='text-justify'>
          Un justificatif d’inscription à un club de sport devra être fourni au moment de la souscription pour bénéficier de la réduction.
          <span className='underline font-bold cursor-pointer' onClick={handleModal}>Voir les conditions</span>
        </span>
      </div>
      <div className={styles.informations}>
        <span
          onClick={() => setQuoteDataPersonnalInformation({ check: !quoteData.member.check })}
          className='flex items-center justify-center cursor-pointer'
          style={{ height: 18, width: 18, backgroundColor: quoteData.member.check ? '#9FC500' : '#fff', borderRadius: 4, border: '1px solid #9FC500' }}>
          <Checkbox />
        </span>
        <span className='flex flex-1'>
          Je déclare adhérer à l’association A.M.V.E.M.
          (Association Mutuelle Verte des Expatriés au Maghreb)
          et j’adhère au contrat collectif de prévoyance complémentaire
          santé à adhésion facultative souscrit par l’ A.M.V.E.M.
          auprès de La Mutuelle Verte en vue de permettre à ses adhérents
          de bénéficier de prestations spécifiques liées à leur statut
          d’expatrié ou de détaché dans l’objectif de compléter les prestations
          en nature servies pas la Caisse des Français à l’Etranger (C.F.E.)
          ou pour les salariés détachés par un organisme d’Assurance Maladie Français.
        </span>
      </div>
    </div>
  )
}

export default MyInformations;