import {
  BankDetailsInformations,
  DocumentsInformations,
  GuaranteeInformations,
  QuoteData
} from "src/types/Situation";

import { isInseeValid } from "src/services/socialSecuritySchemeUtils";
import { isDateValid, adhHasMajority } from "src/services/dateUtils";
import { isEmailValid } from "src/services/emailUtils";

// SUBSCRIBTION FORM
const formInvalid = ({
  quoteData,
}: {
  quoteData: QuoteData
}) => {
  if (
    !quoteData.member.civility || String(quoteData.member.civility).length > 1 ||
    !quoteData.member.lastname || quoteData.member.lastname.length > 25 ||
    !quoteData.member.firstname || quoteData.member.firstname.length > 15 ||
    !quoteData.member.birthday || quoteData.member.birthday.length > 10 || !isDateValid(quoteData.member.birthday) || !adhHasMajority(quoteData.member.birthday) ||
    !quoteData.member.streetName || quoteData.member.streetName.length > 27 ||
    (quoteData.member.streetNumber !== '' && quoteData.member.streetNumber.length > 4) ||
    (quoteData.member.streetMore && quoteData.member.streetMore.length > 32) ||
    !quoteData.member.postalCode || quoteData.member.postalCode.length !== 5 ||
    !quoteData.member.country || quoteData.member.country.length > 50 ||
    !quoteData.member.email || !isEmailValid(quoteData.member.email) ||
    !quoteData.member.phone || quoteData.member.phone.length > 10 ||
    !quoteData.member.insee || quoteData.member.insee.length !== 15 || !isInseeValid(quoteData.member.insee) ||
    !quoteData.member.activity || quoteData.member.activity.length > 2 ||
    !quoteData.member.city || quoteData.member.city.length > 50 ||
    !quoteData.member.check
  ) {
    return true;
  }

  if (quoteData.member.currentState === 'allMyFamily' || quoteData.member.currentState === 'meAndMySpouse') {
    if (
      !quoteData.spouse.civility || quoteData.spouse.civility.length > 1 ||
      !quoteData.spouse.lastname || quoteData.spouse.lastname.length > 25 ||
      !quoteData.spouse.firstname || quoteData.spouse.firstname.length > 15 ||
      !quoteData.spouse.birthday || quoteData.spouse.birthday.length > 10 || !isDateValid(quoteData.spouse.birthday) || !adhHasMajority(quoteData.spouse.birthday) ||
      !quoteData.spouse.activity || quoteData.spouse.activity.length > 2 ||
      (quoteData.spouse.socialSecurityConnection === 'other' && !quoteData.spouse.insee)
    ) {
      return true;
    }
  }

  if (quoteData.member.currentState === 'allMyFamily' || quoteData.member.currentState === 'meAndMyChildren') {
    for (const child of quoteData.children) {
      if (
        !child.civility || child.civility.length > 1 ||
        !child.lastname || child.lastname.length > 25 ||
        !child.firstname || child.firstname.length > 15 ||
        !child.birthday || child.birthday.length > 10 || !isDateValid(child.birthday) ||
        (child.socialSecurityConnection === 'other' && !child.insee)
      ) {
        return true;
      }
    }
  }

  return false;
}

const formBankInvalid = ({
  bankDetailsInformations,
}: {
  bankDetailsInformations: BankDetailsInformations;
}) => {
  if (
    !bankDetailsInformations.ownerRib || bankDetailsInformations.ownerRib.length > 23 ||
    !bankDetailsInformations.rib || bankDetailsInformations.rib.length > 23 ||
    !bankDetailsInformations.ibanFee || bankDetailsInformations.ibanFee.length > 34 ||
    !bankDetailsInformations.bicFee || bankDetailsInformations.bicFee.length > 11 ||
    !bankDetailsInformations.dateFee || bankDetailsInformations.dateFee.length > 20
  ) {
    return true;
  }

  if (!bankDetailsInformations.isOwner) {
    if (
      !bankDetailsInformations.additionalAccountAdress || bankDetailsInformations.additionalAccountAdress.length > 100 ||
      !bankDetailsInformations.additionalAccountFirstName || bankDetailsInformations.additionalAccountFirstName.length > 50 ||
      !bankDetailsInformations.additionalAccountLastName || bankDetailsInformations.additionalAccountLastName.length > 50 ||
      !bankDetailsInformations.additionalAccountCity || bankDetailsInformations.additionalAccountCity.length > 50 ||
      !bankDetailsInformations.additionalAccountPostalCode || bankDetailsInformations.additionalAccountPostalCode.length !== 5
    ) {
      return true;
    }
  }

  if (!bankDetailsInformations.sameHolderFee) {
    if (
      !bankDetailsInformations.refundAccoundRib || bankDetailsInformations.refundAccoundRib.length > 23 ||
      !bankDetailsInformations.refundAccountBic || bankDetailsInformations.refundAccountBic.length !== 11 ||
      !bankDetailsInformations.refundAccountIban || bankDetailsInformations.refundAccountIban.length > 34 ||
      !bankDetailsInformations.refundAccoundOwner || bankDetailsInformations.refundAccoundOwner.length > 50
    ) {
      return true;
    }
  }

  if (!bankDetailsInformations.refundSpouseSameAccount) {
    if (
      !bankDetailsInformations.refundSpouseAccoundRib || bankDetailsInformations.refundSpouseAccoundRib.length > 23 ||
      !bankDetailsInformations.refundSpouseAccountBic || bankDetailsInformations.refundSpouseAccountBic.length !== 11 ||
      !bankDetailsInformations.refundSpouseAccountIban || bankDetailsInformations.refundSpouseAccountIban.length > 34 ||
      !bankDetailsInformations.refundSpouseAccoundOwner || bankDetailsInformations.refundSpouseAccoundOwner.length > 50
    ) {
      return true;
    }
  }

  return false;
}

const formDocumentsInformationsInvalid = ({
  documentsInformations,
}: {
  documentsInformations: DocumentsInformations
}) => (
  !documentsInformations.bankIdentityDocument ||
  !documentsInformations.healthInsuranceCertificate ||
  !documentsInformations.identifySpouseDocument ||
  !documentsInformations.identityDocument ||
  !documentsInformations.sportCertificate
)

const formGuaranteeInformationsInvalid = ({
  guaranteeInformations,
}: {
  guaranteeInformations: GuaranteeInformations
}) => {
  if (!guaranteeInformations.startingDate) {
    return true
  }

  if (guaranteeInformations.hasBeenSponsored) {
    if (!guaranteeInformations.sponsoredName ||
      !guaranteeInformations.sponsoredReference) {
      return true
    }
  }

  if (guaranteeInformations.hasComplementaryHealthInsurance && guaranteeInformations.needWipeOut) {
    if (!guaranteeInformations.currentHealthInsuranceName ||
      !guaranteeInformations.currentHealthInsuranceReference ||
      !guaranteeInformations.adressWipeOut ||
      !guaranteeInformations.postalCodeWipeOut ||
      !guaranteeInformations.cityWipeOut ||
      !guaranteeInformations.countryWipeOut
    ) {
      return true
    }
  }

  return false;
}

export {
  formInvalid,
  formBankInvalid,
  formDocumentsInformationsInvalid,
  formGuaranteeInformationsInvalid
}