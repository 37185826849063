import React from 'react';

import useDevis from 'src/hooks/useDevis';
import NextButton from "src/components/Ui/NextButton";
import AdditionalOffers from "src/components/Ui/AdditionalOffers";
import ChildrenOfferModal from 'src/components/Ui/Modals/ChildrenModal';
import Plus from "src/assets/Plus";
import Minus from "src/assets/Minus";
import BigStar from 'src/assets/Stars/Big';
import { SituationState, SubChildrenInformations } from 'src/types/Situation';
import { formDevisChildrenInformationsInvalid } from 'src/services/validatorsDevis';
import Input from 'src/components/Ui/Input';
import styles from './styles.module.css';

const Children = () => {
  const context = useDevis();

  const { situationState: { children }, setSituationState, setStep, situationStep, maxSteps, setSituationStep } = context;

  const [modal, setModal] = React.useState(false);

  return (
    <div className={`flex justify-center p-4 gap-12 rounded-md items-start ${styles.responsivContainer}`}>
      <div className={`flex flex-col items-start space-y-4 bg-blue-100 p-14 relative overflow-hidden ${styles.section}`}>
        <span className={styles.steps}>
          {situationStep}/{maxSteps}
        </span>
        <h1 className="text-xl mb-4 font-bold text-blue-900">J’ajoute mes enfants</h1>
        <label htmlFor="children" className={`flex items-center ${styles.label}`}>
          Nombre d'enfants
        </label>
        <span className={styles.counterContainer}>

          <span onClick={() => {
            if (children.length > 0) {
              const newChildren = [...children];
              newChildren.pop();
              setSituationState((prevState: SituationState) => ({
                ...prevState,
                children: newChildren
              }))
            }
          }} className={styles.counterButton}>
            <Minus />
          </span>

          <span className={styles.counter}>{children.length}</span>

          <span onClick={() => {
            const newChild: SubChildrenInformations = {
              civility: '',
              firstname: '',
              lastname: '',
              birthday: '',
              socialSecurityConnection: '',
              insee: '',
              socialSecurityScheme: ''
            };
            setSituationState((prevState: SituationState) => ({
              ...prevState,
              children: [
                ...prevState.children,
                newChild
              ]
            }))
          }} className={styles.counterButton}>
            <Plus />
          </span>
        </span>
        {
          children.length > 0 && (
            children.map((child, index) => (
              <div key={`child${index + 1}`}>
                <Input
                  value={child.birthday}
                  onChange={(e) => {
                    const newChildren = [...children];
                    newChildren[index] = {
                      ...newChildren[index],
                      birthday: e as string
                    };
                    setSituationState((prevState: SituationState) => ({
                      ...prevState,
                      children: [
                        ...newChildren
                      ]
                    }))
                  }}
                  type='date'
                  id='birthday'
                  maxDate={new Date().toISOString().split('T')[0]}
                  label={`Date de naissance de mon ${`${index + 1}e`} enfant *`}
                  maxLength={10}
                  error={child.birthday === ''}
                />
              </div>
            ))
          )
        }

        <div className='flex justify-end w-full'>
          <p style={{ color: '#243E69', fontSize: '12px', fontWeight: 400 }}>(*) : champs obligatoires</p>
        </div>
      </div>

      <AdditionalOffers
        image="/images/children-offer.jpg"
        additionalComponent={(
          <div className={styles.reduction}>
            <div className='relative'>
              <BigStar />
              <span className={styles.reductionText}>Cotisation Offerte</span>
            </div>
          </div>
        )}
        title="À partir du 3e enfant"
        description="La cotisation est offerte !"
        action={() => {
          setModal(true);
        }}
      />

      {modal && (
        <ChildrenOfferModal
          close={() => setModal(false)}
        />
      )}
      <NextButton
        canNext={!formDevisChildrenInformationsInvalid(children)}
        canPrev={true}
        nextStep={() => setStep(1)}
        prevStep={() => { maxSteps === 3 ? setSituationStep(2) : setSituationStep(1) }}
      />
    </div>
  )
}

export default Children;