import React from 'react';

import { Devis } from 'src/types/Situation';

import useDevis from 'src/hooks/useDevis';
import Gift from 'src/assets/Devis/Gift';
import Download from 'src/assets/Needed/Download';
import Bell from 'src/assets/Devis/Bell';
import SeeMore from 'src/assets/SeeMore';

import styles from './styles.module.css';
import ConfirmationDocuments from 'src/assets/Devis/Confirmation/Documents';

interface DevisResumeProps {
  devis: Devis;
  withoutSubscribeButton?: boolean;
  actionConditions?: () => void;
}

const DevisResume = ({
  devis,
  withoutSubscribeButton = false,
  actionConditions = () => { }
}: DevisResumeProps) => {
  const [containerShow, setContainerShow] = React.useState(true);
  const context = useDevis();
  const { situationState, quoteNumber } = context;

  function redirect() {
    window.location.href = `/subscription/${quoteNumber}`;
  }

  return (
    <div className={`m-auto md:m-0 w-full md:w-auto ${styles.container}`}>
      <h2 className={styles.title}>Ma garantie</h2>
      <div className={styles.overflowContainer}>
        <div className={styles.card}>
          <div className={styles.cardBanner} />
          <h2 className={styles.title}>{devis.name}</h2>
          <span className={styles.price}>
            <span className={styles.priceValue}>{devis.price}€</span>
            /mois
          </span>
          <p className={styles.tva}>
            Ce tarif inclus les taxes {'true' === situationState.athletic && ('et les moins 10% pour les sportifs')}
          </p>
          {!withoutSubscribeButton &&
            <button className={styles.button} onClick={() => { redirect(); }}>Souscrire en ligne</button>
          }
          <div className={styles.mobileButton} onClick={() => {
            setContainerShow(!containerShow);
          }}>
            {containerShow ? 'Voir moins' : 'Voir plus'}
            <span className={containerShow ? styles.arrowUp : styles.arrowDown}>
              <SeeMore />
            </span>
          </div>
        </div>
      </div>
      {containerShow && (
        <div className={styles.mobileContainer}>
          {withoutSubscribeButton && <span className={styles.code}>Référence de devis : {devis.code}</span>}
          <span className={styles.categories}>
            <Bell /> Services inclus
          </span>
          <ul className={styles.list}>
            {devis.includedServices.map((service, index) => (
              <li key={index} className={styles.listItem}>{service}</li>
            ))}
          </ul>
          <span className={styles.separator} />
          <span className={styles.categories}>
            <Gift /> Avantages *
          </span>
          <ul className={styles.list}>
            {devis.includedBenetifs.map((advantage, index) => (
              <li key={index} className={styles.listItem}>{advantage}</li>
            ))}
          </ul>
          <span className={styles.separator} />
          <span className={`${styles.categories}`}><ConfirmationDocuments color='white' /> Documents à télécharger</span>
          {!withoutSubscribeButton && devis.documents.map((document, index) => (
            <button
              className={`${styles.buttonGuarantee}`}
              onClick={() => {
                window.open(document.url, '_blank');
              }}
            >
              {document.title}
              <Download />
            </button>
          ))}
          <p className={styles.asterisk} onClick={actionConditions}>
            * Voir les <span className="underline">conditions</span>
          </p>
        </div>
      )}
    </div>
  )
}

export default DevisResume;